import dbConnect from '../utils/dbConnect';
import type { NextHandler } from 'next-connect';
import type { ApiRequestWithDatabase } from '../server/ApiRequestWithDatabase';
import type { ApiResponseWithCompany } from '../server/ApiResponseWithCompany';

const useDatabase = async (req: ApiRequestWithDatabase) => {
  if (!req.db) {
    const connection = await dbConnect();
    req.db = connection.db;
  }
};

export const database = async (
  req: ApiRequestWithDatabase,
  res: ApiResponseWithCompany,
  next: NextHandler
) => {
  if (!req.db) {
    const connection = await dbConnect();
    req.db = connection.db;
  }
  return next();
};

export default useDatabase;
