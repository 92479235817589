/* This is a database connection function*/
import mongoose from 'mongoose';

export type Connection = {
  isConnected: number;
  db?: any;
};

const connection: Connection = {
  isConnected: 0,
  db: false
}; /* creating connection object*/

async function dbConnect() {
  /* check if we have connection to our databse*/
  if (connection.isConnected) {
    return connection;
  }
  const uri = process.env.DB_CONNECTION;
  /* connecting to our database */
  const db = await mongoose.connect(uri, {
    useNewUrlParser: true,
    useUnifiedTopology: true,
    useFindAndModify: false
  });
  connection.isConnected = db.connections[0].readyState;
  connection.db = db;
  return connection;
}

export default dbConnect;
