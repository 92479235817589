import App from 'next/app';
import type { AppProps /*, AppContext */ } from 'next/app';
import { init } from '../utils/sentry';
import { Provider } from 'react-redux';
import { useStore } from '../stores/redux';
import 'bootstrap/dist/css/bootstrap.css';
import '../styles/globals.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'cropperjs/dist/cropper.css';
import { applySubdomain } from '../middleware/useSubdomain';

init();

function MyApp({ Component, pageProps }: AppProps) {
  const reduxStore = useStore({
    ...pageProps.initialReduxState,
    company: pageProps.company || {}
  });
  return (
    <Provider store={reduxStore}>
      <Component {...pageProps} />
    </Provider>
  );
}

MyApp.getInitialProps = async (appContext) => {
  await applySubdomain(
    appContext.ctx.req,
    appContext.ctx.res,
    appContext.ctx.query
  );
  const { company } = appContext.ctx.res;
  // console.log(appContext.ctx);
  //   // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);
  return { ...appProps, company: company ? JSON.parse(company) : {} };
};

export default MyApp;
