import { createStore, applyMiddleware } from 'redux';
import { useMemo } from 'react';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import withRedux from 'next-redux-wrapper';
import { rootReducer } from 'fast-redux';

export const initStore = (initialState = {}) => {
  return createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(thunkMiddleware))
  );
};

export function useStore(initialState) {
  const store = useMemo(() => initStore(initialState), [initialState]);
  return store;
}

export const reduxPage = (comp) => withRedux(initStore)(comp);
