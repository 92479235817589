import mongoose from 'mongoose';
import { TieredPrice } from './product';

type CustomizationOptions = {
  homepageImage?: string;
  backgroundImage?: string;
  backgroundColor?: string;
  logo?: string;
};

export interface CompanyView {
  legalName: string;
  shortName: string;
  approvalEmail: string;
  address: string;
  city: string;
  state: string;
  zip: number;
  url: string;
  subdomain: string;
  leadTime: number;
  beverageType?: string;
  stripeToken?: string;
  customization: CustomizationOptions;
  salesTax: number;
  printCostPerCase: number;
  deliveryCost: number;
  trayCost: TieredPrice[];
  setupFee: number;
}

export interface CompanyInterface extends mongoose.Document {
  legalName: string;
  shortName: string;
  approvalEmail: string;
  address: string;
  city: string;
  state: string;
  zip: number;
  url: string;
  subdomain: string;
  leadTime: number;
  beverageType?: string;
  stripeToken?: string;
  customization: CustomizationOptions;
  salesTax: number;
  printCostPerCan: number;
  deliveryCost: number;
  trayCost: TieredPrice[];
  setupFee: number;
}

// Model is from mongoose.Model
interface CompanyModelInterface extends mongoose.Model<CompanyInterface> {
  // declare any static methods here
  findBySubdomain(): any; // this should be changed to the correct return type if possible.
}

const CompanySchema = new mongoose.Schema(
  {
    legalName: {
      /* The name of this pet */

      type: String,
      required: [true, 'Please provide a name.'],
      maxlength: [100, 'Name cannot be more than 100 characters']
    },
    shortName: {
      /* The name of this pet */

      type: String,
      required: [true, 'Please provide a shortname.'],
      maxlength: [25, 'Name cannot be more than 25 characters']
    },
    approvalEmail: {
      type: String,
      required: [true, 'Please provide an approval email']
    },
    address: {
      type: String,
      required: [true, 'Please provide an address.']
    },
    city: {
      type: String,
      required: [true, 'Please provide a city.']
    },
    state: {
      type: String,
      required: [true, 'Please provide a state.']
    },
    zip: {
      type: Number,
      required: [true, 'Please provide a zip code.']
    },
    url: {
      type: String,
      required: [true, 'Please provide a website url.']
    },
    subdomain: {
      type: String,
      required: [true, 'Please provide a subdomaind.'],
      maxlength: [50, 'Subdomain cannot be longer than 50 characters']
    },
    beverageType: {
      type: String
    },
    leadTime: {
      type: Number,
      required: [true, 'Please provide a lead time in days.']
    },
    turnAroundTime: {
      type: Number,
      required: [
        true,
        'Please provide an approval/rejection turnaround time in days.'
      ]
    },
    stripeToken: {
      type: String
    },
    customization: {
      type: Map,
      required: [true, 'Please provide some customizations.']
    },
    printCostPerCan: {
      type: Number,
      required: [true, 'Please provide printCostPerCase.']
    },
    deliveryCost: {
      type: Number,
      required: [true, 'Please provide deliveryCost.']
    },
    trayCost: [
      {
        _id: false,
        tier: Number,
        price: Number
      }
    ],
    setupFee: {
      type: Number,
      required: [true, 'Please provide setupFee.']
    },
    salesTax: Number,
    approvalTemplate: String,
    rejectionTemplate: String
  },
  { collection: 'companies' }
);

CompanySchema.statics.findBySubdomain = async function (subdomain: string) {
  return await this.findOne({ subdomain }).lean();
};

export default (mongoose.models && mongoose.models.Company) ||
  mongoose.model<CompanyInterface, CompanyModelInterface>(
    'Company',
    CompanySchema
  );
