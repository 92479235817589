import type { NextHandler } from 'next-connect';
import type { ApiRequestWithDatabase } from '../server/ApiRequestWithDatabase';
import type { ApiResponseWithCompany } from '../server/ApiResponseWithCompany';
import useDatabase from './useDatabase';
import Company from '../models/company';
import { applyNextSession } from '../utils/session';

const parseSubdomain = (req, query = null) => {
  if (query && query.company) {
    return query.company;
  }
  const baseUrl: string =
    process.env.BASE_URL || process.env.NEXT_PUBLIC_BASE_URL;
  let host: string;
  let sub: string;
  if (req && req.headers.host) {
    host = req.headers.host;
  }
  if (typeof window !== 'undefined') {
    host = window.location.host;
  }
  let subdomain: string;
  if (host && host.includes(baseUrl)) {
    sub = host.split(baseUrl)[0];
    if (sub) {
      subdomain = sub.split('.')[0];
    }
  }
  return subdomain;
};

const setCompany = async (req, res, subdomain) => {
  req.subdomain = subdomain;
  await useDatabase(req);
  const company = await Company.findOne({ subdomain }).exec();
  res.company = JSON.stringify(company);
  req.session.set('subdomain', subdomain);
  req.session.set('company', JSON.stringify(company));
  await req.session.save();
};

const getSubdomain = async (
  req: ApiRequestWithDatabase,
  res: ApiResponseWithCompany,
  query?: any
) => {
  const subdomain = parseSubdomain(req, query);
  if (subdomain) {
    await setCompany(req, res, subdomain);
  }
};

export const useCompany = async (
  req: ApiRequestWithDatabase,
  res: ApiResponseWithCompany,
  next: NextHandler
) => {
  if (req.session.get('subdomain')) {
    req.subdomain = req.session.get('subdomain');
    res.company = req.session.get('company');
    return next();
  }

  await getSubdomain(req, res);
  return next();
};

export async function applySubdomain(req, res, query = null) {
  await applyNextSession(req, res);
  if (req.session.get('subdomain')) {
    req.subdomain = req.session.get('subdomain');
    res.company = req.session.get('company');
    return;
  }
  if (query && query.company) {
    return await setCompany(req, res, query.company);
  }
  return await getSubdomain(req, res);
}
