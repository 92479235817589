import { withIronSession, applySession } from 'next-iron-session';

export default function withSession(handler) {
  return withIronSession(handler, {
    password: process.env.JWT_SECRET,
    cookieName: '_NEXT_Canimal_Secret',
    ttl: 86400,
    cookieOptions: {
      // the next line allows to use the session in non-https environments like
      // Next.js dev mode (http://localhost:3000)
      secure: process.env.NODE_ENV === 'production' ? true : false
    }
  });
}
export const applyNextSession = async (req, res) => {
  return applySession(req, res, {
    password: process.env.JWT_SECRET,
    cookieName: '_NEXT_Canimal_Secret',
    ttl: 86400,
    cookieOptions: {
      // the next line allows to use the session in non-https environments like
      // Next.js dev mode (http://localhost:3000)
      secure: process.env.NODE_ENV === 'production' ? true : false
    }
  });
};
